import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "pafos_maslac" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Футбольный клуб «Пафос» после того, как проиграл дело против Марио Маслача в палате по решению споров ФИФА, привлёк агентство Catenaccio для ведения переговоров и нахождения компромисса, который бы устроил обе стороны конфликта. Спустя месяц переговоров соглашение о приостановлении всех претензий было подписано. Все действующие стороны остались довольны и претензий друг к другу больше не имеют.</p>
        </div>
      </div>
    )
  }
}